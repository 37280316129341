import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";

export const frontmatter = {
  title: "Día 40",
  week: "Semana 6",
  month: "abr",
  day: "22",
  monthNumber: "04",
  date: "2020-04-22",
  path: "/cronologia/semana-06#dia-22-abr",
};
const Day40 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDatafactCCAA
          fecha={"2020-04-22"}
          ccaa="es-pv"
          customText="Tasa de letalidad"
          customNumber="8,6%"
        />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-22"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.211 nuevos positivos y 435 personas fallecidas.
          Permanecen hospitalizadas 77.609 personas, 506 más que el día
          anterior, y 3.401 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.700, lo que supone una disminución de -5 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,4 % y la tasa de recuperados del 41,2 %.
        </ModText>
        <ModGraph
          name="22-04-evolucion-pv"
          alt="confirmados por coronavirus provincias castilla la mancha"
        />
        <ModText>
          En el País Vasco la evolución permite ver que, aunque el número de
          contagiados sigua creaciendo, las entradas a los hospitales se
          estabilizan, y las salidas de los centros sanitarios crecen de forma
          más fuerte, relajando la presión sobre los centros del sistema
          sanitario.
        </ModText>
        <ModImage
          src="/images/svg/22_abr_investigacion-genetica.svg"
          alt="investigacion genética covid-19"
        />
        <ModText>
          CSIC ha puesto en marcha un estudio genético para identificar el
          riesgo individual de desarrollar formas graves de COVID-19. A través
          del análisis del sistema de defensas innato de cada persona, se
          pretende identificar las probabilidades de contraerlo.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day40;
